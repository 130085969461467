import './TourCard.scss';

import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { useControllers } from '../../controllers/app.controller';
import { pipe } from '../../scripts/helpers/pipe';
import { WpProduct } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { fromCharCode } from '../../utils/string.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseImage from '../BaseImage/BaseImage';
import { ID_OverlayProduct } from '../OverlayProduct/OverlayProduct';
import BaseLink from '../BaseLink/BaseLink';

const getClassNameForValueTag = (valueTag: string) => {
  const tag = valueTag?.toLowerCase().replaceAll(/[ -]/g, '');
  switch (tag) {
    case 'greatvalue':
      return 'yellow'
    case 'popular':
    default:
      return ''
  }
}

type TourCardProps = {
  product: WpProduct,
  shortenPrice?: boolean,
}

const TourCard: React.FC<TourCardProps> = props => {

  const p = props;
  const s = {
    get product() { return p.product },
    get featuredImage() { return s.product.featuredImage?.node },
    get categories() { return s.product.categories.nodes },
    get locations() { return s.product.productFields?.locations ?? [] },
    get isTour() {
      return s.categories.find(c => c.slug === 'tours-trips');
    },
    get slug() { return s.product.slug },
    get title() {
      return s.renderString(s.product.title);
    },
    get afterTitle() {
      return p.product.productFields?.subheading;
    },
    get valueTag() {
      return p.product.productFields?.valueTag;
    },
    get priceInfo() {
      const price = p.product.productFields?.price;
      // if (price.length > 8 && price.includes('quote') && p.shortenPrice) return "Contact for quote";
      // if (price.includes('quote')) return price;
      if (price.includes('quote')) return '';
      return `$ ${price}`
    },
    renderString(value: string) {
      return value ? pipe(fromCharCode, sanitizeHtml)(value) : "";
    },
  }
  return <div className={joinClassNames("TourCard")}>
    <div className="TourCard__inner">
      <header className="TourCard__header">
        <h3 className="TourCard__title">{s.title}</h3>
        {s.afterTitle && <p className="TourCard__afterTitle">{s.afterTitle}</p>}
        <div className="TourCard__footer">
          <BaseLink to={`/explore/#tour?${ID_OverlayProduct}=${s.slug}`} stopPropagation>
            <BaseButton>Book Now</BaseButton>
          </BaseLink>
          {s.priceInfo && <p className="TourCard__price">{s.priceInfo}</p>}
        </div>
      </header>
      <figure className="TourCard__figure">
        {s.featuredImage && <BaseImage
          className="filler SectionToursCards-slides__slide__image-filler"
          media={s.featuredImage}
          imageType='gatsbyDynamic'
        />}
      </figure>
      {s.valueTag && <div
        className={joinClassNames('TourCard__valueTag', getClassNameForValueTag(s.valueTag))}
      >
        {s.valueTag}
      </div>}
    </div>
  </div>
}

export default TourCard;