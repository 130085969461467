import './PageSectionToursCards.scss';

import { graphql } from 'gatsby';
import React from 'react';

import { WpFrontPageSectionToursCards } from '../../../types/wordpress.types';
import { useProps, useStore } from '../../../utils/mobx.utils';
import { sanitize } from '../../../utils/sanitize.utils';
import { isNumberLike } from '../../../utils/typeChecks.utils';
import BaseIcon from '../../BaseIcon/BaseIcon';
import TourCard from '../../TourCard/TourCard';
import HorizontalSwiper from '../../HorizontalSwiper/HorizontalSwiper';
import { useControllers } from '../../../controllers/app.controller';
import { Observer } from 'mobx-react-lite';
import { useOnMount } from '../../../hooks/lifecycle.hooks';
import { flow, reaction } from 'mobx';
import { useCreateResizeQueryWithRef } from '../../../hooks/useCreateResizeQueryWithRef.hook';
import APP_CONTENT_CONFIG from '../../../constants/appContentConfig.constants';
import PageSection from '../../PageSection/PageSection';

export const PageSectionToursCardsFragment = graphql`
  fragment PageSectionToursCardsFragment on WpTemplate_FrontPage {
    frontPageFields {
      sectionToursCards {
        body
        heading
        appIconName
        tourProducts {
          ... on WpProduct {
            ...ProductWithFeaturedImageFragment
            ...ProductProductFieldsFragment
          }
        }
      }
    }
  }
`;

type PageSectionToursCardsProps = WpFrontPageSectionToursCards & {
}

const PageSectionToursCards = (props: PageSectionToursCardsProps) => {
  const { ref: swiperRef, query: swiperQuery } = useCreateResizeQueryWithRef<HTMLDivElement>();
  const { UI } = useControllers();
  const p = props;
  const s = useStore(() => ({
    shouldUseSwiper: true as boolean,
    lastSwiperWidth: 0 as number,
    get icon() {
      return p.appIconName ?? undefined;
    },
    get sortedProductsByPrice() {
      return p.tourProducts.slice().sort((a, b) => {
        const v1 = b.productFields?.price;
        const v2 = a.productFields?.price;
        return (isNumberLike(v1) ? +v1 : 0) - (isNumberLike(v2) ? +v2 : 0);
      });
    },
    get TourCardsView() {
      return <>
        {s.sortedProductsByPrice.map(product => (
          <React.Fragment key={product.id}>
            <TourCard product={product} shortenPrice />
          </React.Fragment>
        ))}
      </>
    }
  }))
  useOnMount(() => {
    return reaction(
      () => `${UI.appWidth}_${swiperQuery.width}`,
      flow(function* () {
        const containerWidth = swiperQuery.width || s.lastSwiperWidth;
        const viewportWidth = window.innerWidth;
        if (containerWidth > viewportWidth) {
          s.shouldUseSwiper = true;
        } else {
          s.shouldUseSwiper = false;
        }
        s.lastSwiperWidth = containerWidth;
      }), { fireImmediately: true }
    )
  })
  const { sectionToursCards } = APP_CONTENT_CONFIG.page.frontPage;
  return <Observer children={() => (
    <PageSection className="page-section SectionToursCards" id="SectionToursCards" observeVisibility>

      <header className='Header'>
        {s.icon && sectionToursCards.content.header.IconFactory(s.icon)}
        <h2 className="Heading">{p.heading}</h2>
        <div className="Body" dangerouslySetInnerHTML={{ __html: sanitize(p.body) }} />
      </header>

      <main className="Main">
        <span style={{ display: !s.shouldUseSwiper ? 'none' : undefined }}><HorizontalSwiper innerRef={swiperRef}>
          {s.TourCardsView}
        </HorizontalSwiper></span>
        {!s.shouldUseSwiper && <div className='SectionToursCardsContainer'>
            {s.TourCardsView}
        </div>
        }
      </main>

    </PageSection>
  )} />
}

export default PageSectionToursCards;